<template>
  <div
    class="home"
    v-infinite-scroll="getList"
    :infinite-scroll-disabled="loadDisabled"
    :infinite-scroll-distance="50"
  >
    <waterfall-list :data="list"></waterfall-list>
    <p class="load-tips" v-if="loading">加载中...</p>
    <p class="load-tips" v-if="finished">没有更多了</p>
  </div>
</template>

<script>
import { debounce, generateListQuery, parseTime, setOptions } from "../../utils/index";
import WaterfallList from "../../components/resources/WaterfallList.vue";
import { getCustomerResources } from "../../api/resources";
export default {
  data() {
    return {
      listQuery: generateListQuery(),
      list: [],
      finished: false,
      loading: false,
    };
  },
  computed: {
    type() {
      return this.$route.query.typeId || "";
    },
    category() {
      return this.$route.params.category || "";
    },
    categoryList() {
      return setOptions("category_list");
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    loadDisabled() {
      return this.loading || this.finished;
    },
  },
  components: {
    WaterfallList,
  },
  created() {
    this.getList();
  },
  methods: {
    parseTime,
    reGetList() {
      this.listQuery.pageNow = 1;
      this.list = [];
      this.getList();
    },
    getList: debounce(
      function () {
        this.loading = true;
        console.log("loading data");

        getCustomerResources({
          ...this.listQuery,
          category: this.category,
          typeId: this.type,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);
          this.list = this.list.concat(res.data.list);
          this.finished = res.data.finished;
          this.loading = false;
          console.log("loading finished");
          this.listQuery.pageNow++;
        });
      },
      50,
      false
    ),
  },
  mounted() {},
  watch: {
    category(newV) {
      this.reGetList();
    },
    type(newV) {
      this.reGetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .load-tips {
    text-align: center;
    color: #909399;
    padding: 10px 20px;
    font-size: 14px;
  }
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button,
::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  width: 0 !important;
}
</style>
